/**
 * Performs a deep clone of an object or array
 */
export function cloneDeep<T>(value: T): T {
  // Handle null, undefined and primitive types
  if (value === null || typeof value !== 'object') {
    return value;
  }

  // Handle Date
  if (value instanceof Date) {
    return new Date(value.getTime()) as any;
  }

  // Handle Array
  if (Array.isArray(value)) {
    return value.map((item) => cloneDeep(item)) as any;
  }

  // Handle Object
  return Object.keys(value).reduce((acc, key) => {
    acc[key as keyof T] = cloneDeep(value[key as keyof T]);
    return acc;
  }, {} as T);
}

/**
 * Performs a deep comparison between two values
 */
export function isEqual(value: any, other: any): boolean {
  // Handle strict equality comparisons
  if (value === other) {
    return true;
  }

  // Handle null/undefined
  if (value == null || other == null) {
    return value === other;
  }

  // Handle different types
  if (typeof value !== typeof other) {
    return false;
  }

  // Handle dates
  if (value instanceof Date && other instanceof Date) {
    return value.getTime() === other.getTime();
  }

  // Handle arrays
  if (Array.isArray(value) && Array.isArray(other)) {
    if (value.length !== other.length) {
      return false;
    }
    return value.every((val, index) => isEqual(val, other[index]));
  }

  // Handle objects
  if (typeof value === 'object') {
    const keys1 = Object.keys(value);
    const keys2 = Object.keys(other);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every(
      (key) => key in other && isEqual(value[key], other[key])
    );
  }

  return false;
}

/**
 * Sorts an array of objects based on object property
 */
export function sortBy<T>(
  array: T[],
  iteratee: keyof T | ((item: T) => any)
): T[] {
  return [...array].sort((a, b) => {
    const valueA = typeof iteratee === 'function' ? iteratee(a) : a[iteratee];
    const valueB = typeof iteratee === 'function' ? iteratee(b) : b[iteratee];

    if (valueA === valueB) return 0;
    if (valueA === null || valueA === undefined) return 1;
    if (valueB === null || valueB === undefined) return -1;

    return valueA < valueB ? -1 : 1;
  });
}

/**
 * Finds the index of the first element in the array that satisfies the predicate
 */
export function findIndex<T>(
  array: T[],
  predicate: ((value: T, index: number, obj: T[]) => boolean) | Partial<T>
): number {
  if (typeof predicate === 'function') {
    return array.findIndex(predicate);
  }

  // Handle object shorthand notation
  return array.findIndex((item) =>
    Object.entries(predicate).every(
      ([key, value]) => item[key as keyof T] === value
    )
  );
}
